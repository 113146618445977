const config = {
    API_ROOT: process.env.REACT_APP_BASE_URL,
    SSO_ROOT:process.env.REACT_APP_SSO_URL,
    FILE_URL: process.env.REACT_APP_FILE_URL,
    CLIENT_ID:process.env.REACT_APP_CLIENT_ID,
    DEFAULT_APP_LANG: 'ru',
    ROLES: {
        ROLE_USER:'ROLE_USER',
        ROLE_ADMIN:'ROLE_ADMIN',
        ROLE_SUPER_ADMIN:'ROLE_SUPER_ADMIN',
        ROLE_PHARMACIST:'ROLE_PHARMACIST',
        ROLE_FUND_MANAGER:'ROLE_FUND_MANAGER',
        ROLE_INPATIENT_SPECIALIST:'ROLE_INPATIENT_SPECIALIST',
        ROLE_INPATIENT_RECEPTIONIST:'ROLE_INPATIENT_RECEPTIONIST',
        ROLE_OUTPATIENT_SPECIALIST:'ROLE_OUTPATIENT_SPECIALIST',
        ROLE_HEAD_INPATIENT:'ROLE_HEAD_INPATIENT',
        ROLE_HEAD_OUTPATIENT:'ROLE_HEAD_OUTPATIENT',
        ROLE_GENERAL_PRACTITIONER:'ROLE_GENERAL_PRACTITIONER',
        ROLE_REGISTRAR:'ROLE_REGISTRAR',
        ROLE_ECONOMIST:'ROLE_ECONOMIST',
        ROLE_HR:'ROLE_HR',
        HOSPITAL_CHAIRMAN:'ROLE_HOSPITAL_CHAIRMAN',
    },
    PERMISSIONS: {
        organization_create:'organization_create',
        organization_view:'organization_view',
        organization_edit:'organization_edit',
        organization_export:'organization_export',
        organization_position_create:'organization_position_create',
        organization_position_view:'organization_position_view',
        organization_position_edit:'organization_position_edit',
        organization_position_delete:'organization_position_delete',
        organization_department_create:'organization_department_create',
        organization_department_view:'organization_department_view',
        organization_department_edit:'organization_department_edit',
        organization_department_delete:'organization_department_delete',
        practitioner_create:'practitioner_create',
        practitioner_view:'practitioner_view',
        practitioner_edit:'practitioner_edit',
        practitioner_export:'practitioner_export',
        practitioner_role_create:'practitioner_role_create',
        practitioner_role_view:'practitioner_role_view',
        practitioner_role_edit:'practitioner_role_edit',
        practitioner_role_delete:'practitioner_role_delete',
        patient_create:'patient_create',
        patient_view:'patient_view',
        patient_edit:'patient_edit',
        patient_gp_edit:'patient_gp_edit',
        patient_export:'patient_export',
        encounter_admission_create:'encounter_admission_create',
        encounter_admission_view:'encounter_admission_view',
        encounter_admission_edit:'encounter_admission_edit',
        encounter_imp_create:'encounter_imp_create',
        encounter_imp_view:'encounter_imp_view',
        encounter_imp_edit:'encounter_imp_edit',
        encounter_imp_delete:'encounter_imp_delete',
        encounter_imp_discharge:'encounter_imp_discharge',
        encounter_imp_export:'encounter_imp_export',
        procedure_create:'procedure_create',
        procedure_view:'procedure_view',
        procedure_edit:'procedure_edit',
        procedure_delete:'procedure_delete',
        observation_create:'observation_create',
        observation_view:'observation_view',
        observation_edit:'observation_edit',
        observation_delete:'observation_delete',
        condition_create:'condition_create',
        condition_view:'condition_view',
        condition_edit:'condition_edit',
        condition_delete:'condition_delete',
        medication_request_create:'medication_request_create',
        medication_request_view:'medication_request_view',
        medication_request_search_by_rx_code:'medication_request_search_by_rx_code',
        medication_request_update_status:'medication_request_update_status',
        medication_request_cancel:'medication_request_cancel',
        medication_request_to_draft:'medication_request_to_draft',
        medication_request_edit:'medication_request_edit',
        medication_request_delete:'medication_request_delete',
        medication_request_confirm:'medication_request_confirm',
        medication_request_export:'medication_request_export',
        medication_dispense_create:'medication_dispense_create',
        medication_dispense_view:'medication_dispense_view',
        medication_dispense_edit:'medication_dispense_edit',
        medication_dispense_confirm:'medication_dispense_confirm',
        medication_dispense_export:'medication_dispense_export',
        budget_view:'budget_view',
        budget_edit:'budget_edit',
        budget_confirm:'budget_confirm',
        budget_export:'budget_export',
        dashboard_view:'dashboard_view',
        episode_of_care_create:'episode_of_care_create',
        episode_of_care_view:'episode_of_care_view',
        episode_of_care_edit:'episode_of_care_edit',
        episode_of_care_delete:'episode_of_care_delete',
    },
}

export default config;
