import React from 'react';
import Brand from "../../../components/brand";
import {useTranslation} from "react-i18next";
import {usePostQuery} from "../../../hooks/api";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {useNavigate} from "react-router-dom";
import {OverlayLoader} from "../../../components/loader";
import {useSettingsStore} from "../../../store";
import {get} from "lodash";

const LoginPage = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {mutate: logoutRequest, isLoading} = usePostQuery({listKeyId: KEYS.logout, sso: true})
    const setToken = useSettingsStore((state) => get(state, 'setToken', () => {
    }))
    const token = useSettingsStore(state => get(state, 'token', null))
    const backToLogin = () => {
        logoutRequest({
                url: URLS.logout,
                config:{
                    headers:{Authorization:`Bearer ${token}`}
                }
            },
            {
                onSuccess: () => {
                    setToken(null)
                    navigate('/auth');
                },
                onError: () => {

                }
            }
        )
    }
    if (isLoading) {
        return <OverlayLoader/>
    }
    return (
        <>
            <div className={'p-8 bg-white rounded-lg'}>
                <div className="text-center mb-6">
                    <Brand className="mb-4 inline-block" mini/>
                    <h2 className={'text-2xl font-bold mb-4'}>{t("Пользователь не найден")}</h2>
                    <button onClick={backToLogin} type={'button'}
                            className={'w-full p-3.5 rounded-lg bg-primary block  text-white font-bold text-center h-12'}>
                        {t("Вернуться к входу")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
