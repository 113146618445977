import React, {useState} from 'react';
import Content from "../../../components/content";
import Title from "../../../components/title";
import {useGetAllQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {get} from "lodash";
import {KEYS} from "../../../constants/keys";
import {useTranslation} from "react-i18next";
import downloadIcon from "../../../assets/icons/download.svg"
import YearMonthPicker from "../../../components/year-month-picker";
import dayjs from "dayjs";
import GridView from "../../../containers/grid-view";
import {NumericFormat} from "react-number-format";
import Button from "../../../components/button";
import {EXCEL_EXPORT_TYPE, FIELDS} from "../../../constants";
import {toast} from "react-toastify";
import {AllowedAccess} from "react-permission-role";
import config from "../../../config";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";

const ReportContainer = () => {
    const {t} = useTranslation()
    const [filter, setFilter] = useState({});
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);

    const {
        refetch, isLoading: isLoadingExportExcel
    } = useGetAllQuery({
        listKeyId: KEYS.exportExcel,
        enabled: false,
        url: `${URLS.exportExcel}/${EXCEL_EXPORT_TYPE.MedicationDispense}`,
        cb: {
            success: (res) => {
                toast.success(get(res, 'message'))
                setExportLoading(false)
            },
            fail: () => {
                setExportLoading(false)
            }
        }
    })
    return (
        <>
            <div className="flex justify-between items-center">
                <Title className={'mb-5'}>{t("АПТЕКА")}</Title>
                <AllowedAccess permissions={[config.PERMISSIONS.medication_dispense_export]}>
                    <Button loading={exportLoading} onClick={() => {
                        setExportLoading(true);
                        refetch();
                    }} icon={<img className={'mr-2.5'} src={downloadIcon} alt={'export'}/>}
                            classNames={'!pr-5 !text-sm '}>
                        {t("Export")}
                    </Button>
                </AllowedAccess>
            </div>
            <Content classNames={'bg-white rounded-2xl !min-h-[calc(100vh-300px)]'}>
                <div className="grid grid-cols-12 gap-x-8">
                    <div className="col-span-12">
                        <div className="mb-5">
                            <YearMonthPicker hasBorder getMonth={setMonth} getYear={setYear}/>
                        </div>
                        <div>
                            <GridView
                                isContentLoader
                                filters={<div className={'pt-0 pb-4 px-0 '}>
                                    <Form resetFields={{
                                        name: '',
                                        patient_id: null,
                                        status_id: null,
                                        requester_id: null,
                                        dispenser_id: null,
                                        rx_code: '',
                                    }}
                                          defaultValues={{...filter}}
                                          classNames={'grid grid-cols-12 gap-x-4 items-start'}
                                          formRequest={({data}) => {
                                              const {
                                                  name,
                                                  status_id,
                                                  rx_code,
                                              } = data;
                                              setFilter({
                                                  name,
                                                  status_id: get(status_id, 'id'),
                                                  rx_code: rx_code,
                                              })
                                          }
                                          }>
                                        <Field classNames={'col-span-3'} type={FIELDS.input} name={'name'}
                                               placeholder={t('Ф.И.О')}/>

                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect} name={'status_id'}
                                               placeholder={t('Status')} url={URLS.medicationrequestStatusV1}
                                               keyId={KEYS.medicationrequestStatusV1}/>


                                        <Field classNames={'col-span-3'} type={FIELDS.input} name={'rx_code'}
                                               placeholder={t('Номер рецепта')}/>
                                        <div className={'col-span-3 mt-1.5'}>
                                            <div className="flex items-center">
                                                <button type={'submit'}
                                                        className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  h-12'}>
                                                    {t('Фильтр')}
                                                </button>
                                                <button onClick={() => setFilter({})} type={'reset'}
                                                        className={'ml-3 text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg inline-block   font-bold text-center h-12'}>
                                                    {t('Очистить фильтр')}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>

                                </div>}
                                bordered
                                dataKey={'data.payload.data'}
                                metaDataKey={'data.payload.meta'}
                                params={{
                                    ...filter,
                                    date_from: month && year ? `${year}-${month}-01` : undefined,
                                    date_to: month && year ? `${year}-${month}-31` : undefined,
                                }}
                                listKey={KEYS.medicationDispense}
                                url={URLS.medicationDispense}
                                columns={[
                                    {
                                        title: t('Номер рецепта'),
                                        key: 'medication_request.rx_code',
                                    },
                                    {
                                        title: t('Brand'),
                                        key: 'brand.display',
                                    },
                                    {
                                        title: t('Наименование лекарства'),
                                        key: 'medication_request.medication.vproduct.display',
                                    },
                                    // {
                                    //     title: t('Срок действия'),
                                    //     key: 'medication_request.valid_until',
                                    //     render: ({value}) => dayjs(value).format('DD.MM.YYYY')
                                    // },
                                    {
                                        title: t('Retsept yozilgan sana'),
                                        key: 'created_at',
                                        render: ({value}) => dayjs(value).format('DD.MM.YYYY')
                                    },
                                    {
                                        title: t('Retsept berilgan sana'),
                                        key: 'confirmed_at',
                                        render: ({value}) => dayjs(value).format('DD.MM.YYYY')
                                    },
                                    {
                                        title: t('Количество выписанного ЛС'),
                                        key: 'medication_request.instructions[0].quantity',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={" "}/>,
                                    },
                                    {
                                        title: t('qty'),
                                        key: 'order_details.qty',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={" "}/>
                                    },
                                    {
                                        title: t('price_per_unit'),
                                        key: 'order_details.price_per_unit',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={" "}/>
                                    },
                                    {
                                        title: t('total_price'),
                                        key: 'order_details.total_price',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={" "}/>
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    );
};

export default ReportContainer;
