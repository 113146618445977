import {useGetAllQuery} from "../../hooks/api";
import {KEYS} from "../../constants/keys";
import {URLS} from "../../constants/urls";
import {useSettingsStore, useStore} from "../../store";
import {get, includes, isNil} from "lodash";
import { usePermission } from 'react-permission-role';
import {useNavigate} from "react-router-dom";
import config from "../../config";

const Auth = ({children}) => {
    const navigate = useNavigate()
    const { setUser:setAuthUser } = usePermission();
    const token = useSettingsStore(state => get(state, "token", null))
    const setUser = useStore(state => get(state, 'setUser', () => {
    }))

    const setAuth = useStore(state => get(state, 'setAuth', () => {
    }))
    const {} = useGetAllQuery({
        key: [KEYS.getMe,token],
        url: URLS.getMe,
        hideErrorMsg: true,
        params: {params: {}},
        enabled: !isNil(token),
        cb: {
            success: (data) => {
                setUser(get(data, 'payload.user'));
                setAuthUser(get(data, 'payload.user'));
                setAuth(true)
                if (includes(get(data,'payload.user.roles',[]),config.ROLES.ROLE_PHARMACIST)){
                    navigate('/pharmacy-search');
                }
            },
            fail: () => {
                navigate('/auth/user-not-found');
            }
        }
    })




    return <>
        {children}
    </>;
};

export default Auth;
