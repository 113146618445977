import React from 'react';
import RolesContainer from "../containers/RolesContainer";

const RolesPage = () => {
    return (
        <>
            <RolesContainer />
        </>
    );
};

export default RolesPage;
