import React, {useState} from 'react';
import Title from "../../../components/title";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {useTranslation} from "react-i18next";
import {useGetAllQuery, usePutQuery} from "../../../hooks/api";
import {OverlayLoader} from "../../../components/loader";
import Content from "../../../components/content";
import {get, includes, isNil} from "lodash";
import {Edit2, User} from "react-feather";
import {Checkbox} from "rsuite";
import Modal from "../../../components/modal";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import {FIELDS} from "../../../constants";


const RolesContainer = () => {
    const [active,setActive] = useState(null)
    const {data: roles, isLoading,refetch} = useGetAllQuery({key: KEYS.roles, url: URLS.roles})
    const {data: permissions, isLoadingPermissions} = useGetAllQuery({key: KEYS.permissions, url: URLS.permissions})
    const {t} = useTranslation()
    const {mutate, isLoading: isLoadingMutate} = usePutQuery({listKeyId: KEYS.roles})
    if (isLoading || isLoadingPermissions) {
        return <OverlayLoader/>
    }
    const setPermissions = ({data: attrs}) => {
        const {permissions: permissionsList = []} = attrs;
        mutate({
            url: `/roles/${get(active,'id')}`,
            attributes: {
                permissions: get(permissions, 'data.payload.data', [])?.filter((_permission, index) => _permission && permissionsList[index]).map(({id}) => id) || []
            }
        }, {
            onSuccess: () => {
                setActive(null)
                refetch()
            }
        })
    }
    return (
        <div>
            <div className="grid grid-cols-12 items-center">
                <div className="col-span-8">
                    <Title>{t("Roles and permissions")}</Title>
                </div>
                <div className="col-span-12 mt-4">
                    <Content sm classNames={'overflow-x-auto'} noPadding>
                        <table className={'border  border-collapse text-center w-full relative bg-white'}>
                            <thead>
                            <tr>
                                <th className={'p-1.5 border min-w-40 sticky left-0 z-50 bg-[#3F6A83]'}><User color={'#fff'}/></th>
                                {get(permissions, 'data.payload.data', []).map(permission => <th
                                    key={get(permission, 'code')}
                                    className={'p-1.5 border text-sm text-left truncate'}>{t(get(permission, 'display'))}</th>)}

                            </tr>
                            </thead>
                            <tbody>
                            {
                                get(roles, 'data.payload.data', []).map(role=> <tr className={'relative'} key={get(role,'code')}>
                                    <td className={'text-left p-1.5 border border-white text-white font-semibold text-lg sticky left-0 z-50 bg-[#3F6A83]'}>{t(get(role,'display'))}</td>
                                    {get(permissions, 'data.payload.data', []).map(permission => <td
                                        key={get(permission, 'code')}
                                        className={'p-1.5 border text-center'}><Checkbox readOnly checked={includes(get(role,'permissions',[]).map(({code})=>code),get(permission, 'code'))} /></td>)}
                                    <td className={'text-right border border-white p-1.5 sticky right-0 z-50 bg-[#3F6A83] cursor-pointer'} onClick={()=>setActive(role)}><Edit2 size={20} color={'#fff'} /></td>

                                </tr>)
                            }
                            </tbody>
                        </table>
                    </Content>
                </div>
            </div>
            <Modal title={get(active,'display')} open={!isNil(active)} onClose={()=>setActive(null)}  classNames={'!w-[1200px] !pb-0'}>
                {isLoadingMutate && <OverlayLoader/>}
                <div className="grid grid-cols-12 gap-x-16">
                    <div className="col-span-12">
                        <Form classNames={'grid grid-cols-12 gap-x-6'}
                              formRequest={(data) => setPermissions(data)}
                              footer={<div className="col-span-12 text-right">
                                  <button type={'submit'}
                                          className={'py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center my-6'}>
                                      {t('Сохранить')}
                                  </button>
                              </div>}>
                            {
                                get(permissions, 'data.payload.data', []).map((permission, index) => <Field
                                    type={FIELDS.switch}
                                    defaultValue={includes(get(active, 'permissions', []).map(({code})=>code), get(permission, 'code'))}
                                    classNames={'col-span-3 mb-5'}
                                    name={`permissions[${index}]`}
                                    label={t(get(permission, 'display'))}
                                />)
                            }
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default RolesContainer;
