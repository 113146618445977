import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {OverlayLoader} from "./components/loader";
import Query from "./services/query";
import Router from "./router/router";
import reportWebVitals from './reportWebVitals';
import Auth from "./services/auth/Auth";
import {ToastContainer} from "react-toastify";
import "nprogress/nprogress.css";
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/index.css';
import './services/i18n'
import {PermissionProvider} from "react-permission-role";
import {BrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";
Sentry.init({
    dsn: "https://964f4610b4a17f2d5e2611580d70d7b7@sentry.dtsj.uz/6",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<OverlayLoader/>}>
        <Query>
            <PermissionProvider>
                <BrowserRouter>
                    <Auth>
                        <Router/>
                    </Auth>
                </BrowserRouter>
            </PermissionProvider>
        </Query>
        <ToastContainer/>
    </Suspense>
);

reportWebVitals();
