import React from 'react';
import {NavLink} from "react-router-dom";
import logo from "../../assets/images/logo-new.svg";
import logoMini from "../../assets/images/logo-new.svg";
import clsx from "clsx";
import {useStore} from "../../store";
import {get,includes} from "lodash";
import config from "../../config";

const Brand = ({className='',mini=false}) => {
    const user = useStore(state => get(state, 'user', null))
    return (
        <NavLink className={clsx('inline-flex items-end',className)} to={includes(get(user,'roles',[]),config.ROLES.ROLE_PHARMACIST) ? '#' : '/'}>
            <img className={'h-12'} src={mini ? logoMini :logo} alt="logo"/>
            {!mini && <span className={'text-[#000C36] font-bold text-2xl ml-1 leading-none relative -bottom-1'}>Express</span>}
        </NavLink>
    );
};

export default Brand;
