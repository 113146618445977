export const KEYS = {
    oauth: 'oauth',
    token: 'token',
    logout:'logout',
    getMe: 'getMe',
    login: 'login',
    locales: 'locales',
    translations: 'translations',
    organizations: 'organizations',
    organizationsListForSelect: 'organizationsListForSelect',
    organizationTypeLevel: 'organizationTypeLevel',
    organizationTypeMedical: 'organizationTypeMedical',
    organizationLegalForm: 'organizationLegalForm',
    organizationTypeService: 'organizationTypeService',
    organizationManagementForm: 'organizationManagementForm',
    organizationCountry: 'organizationCountry',
    organizationTerritory: 'organizationTerritory',
    organizationDepartments: 'organizationDepartments',
    organizationPositions: 'organizationPositions',
    organizationType: 'organizationType',
    practitionerRole: 'practitionerRole',
    practitioners: 'practitioners',
    roles: 'roles',
    persons: 'persons',
    patients: 'patients',
    nationality: 'nationality',
    citizenship: 'citizenship',
    gender: 'gender',
    identifierType: 'identifierType',
    addressUse: 'addressUse',
    episodeOfCares: 'episodeOfCares',
    conditionSeverity: 'conditionSeverity',
    icd10: 'icd10',
    episodeOfCareStatus: 'episodeOfCareStatus',
    encounters: 'encounters',
    encounterClass: 'encounterClass',
    encounterLocationStatus: 'encounterLocationStatus',
    serviceType: 'serviceType',
    medicationRequestCategory: 'medicationRequestCategory',
    medicationInn: 'medicationInn',
    ucumCommon: 'ucumCommon',
    medicationFormCode: 'medicationFormCode',
    medicationDosageMethod: 'medicationDosageMethod',
    medicationRequest: 'medicationRequest',
    medicationConcept: 'medicationConcept',
    medicationDoseUnit: 'medicationDoseUnit',
    medicationQuantityUnit: 'medicationQuantityUnit',
    medicationrequestType: 'medicationrequestType',
    routeCode: 'routeCode',
    timingAbbreviation: 'timingAbbreviation',
    episodeOfCaresStatusReason: 'episodeOfCaresStatusReason',
    episodeOfCaresDischargeReason: 'episodeOfCaresDischargeReason',
    medicationVirtualProducts: 'medicationVirtualProducts',
    medicationrequestStatusV1: 'medicationrequestStatusV1',
    medicationRequestGroup: 'medicationRequestGroup',
    medicationDictionaryList: 'medicationDictionaryList',
    medicationDispense: 'medicationDispense',
    budget: 'budget',
    statistics: 'statistics',
    statisticsByPeriod: 'statisticsByPeriod',
    statisticsRealization: 'statisticsRealization',
    notifications:'notifications',
    exportExcel:'exportExcel',
    medicationDosageUnit:'medicationDosageUnit',
    medicationBrands:'medicationBrands',
    budgetStates:'budgetStates',
    getBalanceByStates:'getBalanceByStates',
    medicationRequestByGroup:'medicationRequestByGroup',
    medicationRequestByGroups:'medicationRequestByGroups',
    medicationDispenseUpsert:'medicationDispenseUpsert',
    bedType:'bedType',
    encounterPriority:'encounterPriority',
    encounterImp:'encounterImp',
    conditionVerStatus:'conditionVerStatus',
    conditionClinical:'conditionClinical',
    searchDispensable:'searchDispensable',
    medicationRequestStatusReason:'medicationRequestStatusReason',
    practitionerRoleList:'practitionerRoleList',
    diagnosesRubric:'diagnosesRubric',
    concepts:'concepts',
    observationCategory:'observationCategory',
    observations:'observations',
    newborns:'newborns',
    coverageType:'coverageType',
    dischargeDisposition:'dischargeDisposition',
    contactRelationship:'contactRelationship',
    encounterStatus:'encounterStatus',
    getBudgetByOrganization:'getBudgetByOrganization',
    permissions:'permissions'
}
