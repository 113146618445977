import React from 'react';
import StatisticsContainer from "../containers/StatisticsContainer";
import config from "../../../config";
import {AllowedAccess} from "react-permission-role";
import Forbidden from "../../../components/forbidden";

const StatisticsPage = () => {
    return (
        <>
            <AllowedAccess permissions={[config.PERMISSIONS.dashboard_view]} renderAuthFailed={<Forbidden/>}>
                <StatisticsContainer/>
            </AllowedAccess>
        </>
    );
};

export default StatisticsPage;
